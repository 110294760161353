import { DEFAULT_LINK_THEME, DEFAULT_THEME } from "../Components/CallingCardForm/Components/Theme/constant";
import { ThemeLinkCardModel, ThemeModel } from "./Models";

const DEFAULT_BACKGROUND: string = 'bg_website_one';

const APP_VERSION = "1.2";
const APP_NAME = 'Krusho';
const APP_CARD = 'ko-official';
const PAGE_LIMIT = 5;
const PAGE_LIMIT_MAX = 20;
const MAX_PRODUCT_FOR_NOT_PREMIUM = 3;
const PROFILE_CARD_TYPE = 'card';

const APP_CONTACT_INFO = {
    mobile: 98762872828,
    email: "info@krusho.com"
};

export const VALIDATION_RANGE = {
    email: 64,
    description: 256,
    notes: 999,
    id: 128,
    name: 32,
    shortText: 32,
    mediumText: 64,
    longText: 128, 
    mobile: 10,
    cardType: 2,
    minLength: 3,
    pin: 6,
    digit: 10,
    password: 32,
    minPassword: 8,
    maxCard: 999
};

const SERVICE_DESK_STATUS: any = {
    pending: "1",
    assigned: "2",
    resolved: "3",
    hold: "4"
};

const STORAGE_KEY = {
    auth: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFRjEmZ6Y=a',
    selectedUserInfo: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFRjEmZ6YMsui=sui',
    cardData: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83jkasghdkajsajkdskaMSc=cd',
    userFilter: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFRjhsgdkasgdkkjaMSc=uf',
    notifyKey: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFhkasdhkjhakjkahkja=nk',
    versionKey: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFhkasdhkjhakjkahkja=vk',
    emailVerificationKey: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFhkasdhkjhakjkahkja=evk',
    recentlyVisitedCards: 'U2FsdGVkX18Ivpnk6YKie4wQ5eUZM83MFhkasdhkjhakjkahkja=ocl'
};

const ROUTE_KEY = {
  card: '/ko-card',
  support: '/ko-support',
  user: '/ko-user'
};

const ROUTES = {
    loading: '/loading',
    website: '/home',
    login: '/login',
    user: `${ROUTE_KEY.user}/list`,
    emailVerification: (id: any) => `${ROUTE_KEY.user}/email/verification/${id}`,
    resetPassword: `${ROUTE_KEY.user}/password/reset`,
    serviceDesk: `${ROUTE_KEY.support}/queries`,
    serviceDeskList: `${ROUTE_KEY.support}/queries/list`,

    cardList: `${ROUTE_KEY.card}/list`,
    cardAdd: `${ROUTE_KEY.card}/add`,
    cardEdit: (id: any) => `${ROUTE_KEY.card}/${id}/edit`,
    linkCardAdd: `${ROUTE_KEY.card}/link/add`,
    linkCardEdit: (id: any) => `${ROUTE_KEY.card}/link/${id}/edit`,
    payment: (id: any, name: string) => `${ROUTE_KEY.card}/payment/${id}/${name}`,
    enquiry: (id: any, name: string) => `${ROUTE_KEY.card}/enquiry/${id}/${name}`,

    profile: (id: any) => `/${id}`,    
};

const MULTI_RECORD_MAX_LIMIT: number = 10;
const MULTI_RECORD_MAX_LIMIT_PREMIUM: number = 50;
const PRODUCT_RECORD_MAX_LIMIT: number = 5;
const DOCUMENT_RECORD_MAX_LIMIT: number = 3;
const VIEW_CARD_TEMP_STORAGE_TIME: number = 8000;
const NOTIFY_CARD_TEMP_STORAGE_TIME: number = 300000;

const AOS_ANIMATIONS = {
    fadeUp: "fade-up",
    fadeLeft: "fade-left",
    fadeRight: "fade-right",
    fadeDown: "fade-down",
    zoomIn: "zoom-in",
    zoomOut: "zoom-out"
};

const VIEW_CARD_COUNT_TYPE = {
    card: 'CARD',
    contact: 'CONTACT',
    resume: 'RESUME'
};

const CARD_TYPE = {
    link: 1,
    card: 2
}

const mappingThemeValue = (data: any = null) => {
    const theme: ThemeModel = data || DEFAULT_THEME;
    return ({
        profile: theme?.profile, // Profile design type
        profileNameFontSize: theme?.profileNameFontSize, // profile name font size
        profileNameFontStyle: theme?.profileNameFontStyle, // profile name font family
        bodyFontStyle: theme?.bodyFontStyle, // profile name font family
        profileNameFontWeight: theme?.profileNameFontWeight, // profile name font weight
        profileButton: theme?.profileButton, // profile button style
        profileButtonDynamicColor: theme?.profileButtonDynamicColor, //Profile dynamic color in profile contact 4 buttons
        profileImageShape: theme?.profileImageShape, // Profile image shape applicable only for PROFILE one theme
        iconsType: theme?.iconsType, // Icon shape 
        profileWaves: theme?.profileWaves, // Profile wave design in profile card
        themeBodyStyle: theme?.themeBodyStyle,  // Body theme icon or website
        background: theme?.background, // Body card backround color
        header: theme?.header, // Body header type
        orgName: theme?.orgName, //Org name design
        themeBodyIconText: theme?.themeBodyIconText //Body icons needs sub-texts or not
    })
};


const mappingLinkCardThemeValue = (data: any = null) => {
    const theme: ThemeLinkCardModel = data || DEFAULT_LINK_THEME;
    return ({
        profile: theme?.profile, // Profile design type
        profileNameFontSize: theme?.profileNameFontSize, // profile name font size
        profileNameFontStyle: theme?.profileNameFontStyle, // profile name font family
        bodyFontStyle: theme?.bodyFontStyle, // profile name font family
        profileNameFontWeight: theme?.profileNameFontWeight, // profile name font weight
        profileButton: theme?.profileButton, // profile button style
        profileImageShape: theme?.profileImageShape, // Profile image shape applicable only for PROFILE one theme
        iconsType: theme?.iconsType, // Icon shape 
        background: theme?.background, // Body card backround color
        themeBodyIconText: theme?.themeBodyIconText, //Body icons needs sub-texts or not
        profileImageSize: theme?.profileImageSize, //Image size
    })
}

let STYLES: ThemeModel = mappingThemeValue();
let STYLES_LINK_CARD: ThemeLinkCardModel = mappingLinkCardThemeValue();

export {
    STYLES,
    STYLES_LINK_CARD,
    DEFAULT_BACKGROUND,
    mappingThemeValue,
    ROUTES,
    STORAGE_KEY,
    MULTI_RECORD_MAX_LIMIT,
    PRODUCT_RECORD_MAX_LIMIT,
    DOCUMENT_RECORD_MAX_LIMIT,
    APP_NAME,
    APP_CARD,
    VIEW_CARD_TEMP_STORAGE_TIME,
    NOTIFY_CARD_TEMP_STORAGE_TIME,
    VIEW_CARD_COUNT_TYPE,
    PAGE_LIMIT,
    CARD_TYPE,
    APP_CONTACT_INFO,
    APP_VERSION,
    PAGE_LIMIT_MAX,
    MAX_PRODUCT_FOR_NOT_PREMIUM,
    AOS_ANIMATIONS,
    MULTI_RECORD_MAX_LIMIT_PREMIUM,
    SERVICE_DESK_STATUS,
    PROFILE_CARD_TYPE
}
