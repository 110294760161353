import React from 'react';
import { goToCompanyProfileCard } from '../../../utility/common';
import { DEFAULT_BACKGROUND, ROUTES } from '../../../utility/constant';
import { IconWrapper, ICON_TYPE } from '../../Icons';
import { LOGO } from '../../Assets';
import { Image } from "../../Bootstrap";

type Props = {
  background?: string,
  additionalClass?: string
}

const Footer = ({ background, additionalClass = '' }: Props) => {
  const bg = background ? background : DEFAULT_BACKGROUND;

  const goToHomePage = () => {
    const url = window.location.origin + ROUTES.website;
    window.open(url, '_blank');
  };

  return (
    <div className={`p-2 footer ${additionalClass} ${bg}`}>
      <div className="container ">
        <div className="row">
          <div className="row text-center text-white">
            <div className='col-6 my-2'>
              <Image height={30} src={LOGO} onClick={goToHomePage} />
            </div>
            <div className='col-6 icon-design' onClick={goToCompanyProfileCard}>
              <IconWrapper type={ICON_TYPE.arrow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;