import { ThemeLinkCardModel, ThemeModel } from "../../../../utility/Models";
import { THEMES_BACKGROUND } from "../../../../utility/ThemeConstants";

const THEMES_HEADER_TYPE = Object.freeze([
    {
        value: 'profile-one',
    },
    {
        value: 'profile-two'
    },
    {
        value: 'profile-three',
    },
    {
        value: 'profile-four',
        premium: true
    },
    {
        value: 'profile-five',
        premium: true
    },
    {
        value: 'profile-six',
        premium: true
    },
    {
        value: 'profile-seven',
        premium: true
    },
    {
        value: 'profile-eight',
        premium: true
    },
    {
        value: 'profile-nine',
        premium: true
    },
    {
        value: 'profile-ten',
        premium: true
    },
    {
        value: 'profile-eleven',
        premium: true
    },
    {
        value: 'profile-twelve',
        premium: true
    },
    {
        value: 'profile-thirteen',
        premium: true
    },
    {
        value: 'profile-fourteen',
        premium: true
    },
]);

const THEMES_BODY_ICON_NAME_TYPE = Object.freeze([
    {
        value: '',
    },
    {
        value: 'icon-text',
    }
]);

const THEMES_BODY_TYPE = Object.freeze([
    {
        value: 'icon'
    },
    {
        value: 'card'
    }
]);

const THEMES_FONT_FAMILY = Object.freeze([
    {
        value: ''
    },
    {
        value: 'profile-font-family-one'
    },
    {
        value: 'profile-font-family-two',
    },
    {
        value: 'profile-font-family-three',
    },
    {
        value: 'profile-font-family-eleven',
    },
    {
        value: 'profile-font-family-four',
        premium: true
    },
    {
        value: 'profile-font-family-five',
        premium: true
    },
    {
        value: 'profile-font-family-six',
        premium: true
    },
    {
        value: 'profile-font-family-seven',
        premium: true
    },
    {
        value: 'profile-font-family-eight',
        premium: true
    },
    {
        value: 'profile-font-family-nine',
        premium: true
    },
    {
        value: 'profile-font-family-ten',
        premium: true
    },
    {
        value: 'profile-font-family-twelve',
        premium: true
    },
    {
        value: 'profile-font-family-thirteen ',
        premium: true
    },
    {
        value: 'profile-font-family-fourteen ',
        premium: true
    },
    {
        value: 'profile-font-family-fifteen ',
        premium: true
    },
    {
        value: 'profile-font-family-sixteen ',
        premium: true
    },
    {
        value: 'profile-font-family-seventeen',
        premium: true
    }
]);

const THEMES_FONT_SIZE = Object.freeze([
    {
        value: 'profile-name-size-small'
    },
    {
        value: ''
    }
]);

const THEMES_FONT_WEIGHT = Object.freeze([
    {
        value: ''
    },
    {
        value: 'profile-font-weight-one'
    }
]);

const THEMES_ICONS_ROUNDED = Object.freeze([
    {
        value: 'rounded-0'
    },
    {
        value: 'rounded-1',
        premium: true
    },
    {
        value: 'rounded-2',
        premium: true
    },
    {
        value: 'rounded-3',
        premium: true
    },
    {
        value: 'rounded-4',
        premium: true
    },
    {
        value: 'rounded-5',
        premium: true
    },
    {
        value: 'shape_one'
    },
    {
        value: 'shape_two'
    },
    {
        value: 'shape_three'
    },
    {
        value: 'shape_four'
    },
    {
        value: 'rounded-bottom-4',
        premium: true
    },
    {
        value: 'rounded-bottom-circle',
        premium: true
    },
    {
        value: 'rounded-bottom-pill',
        premium: true
    },
    {
        value: 'rounded-top-4',
        premium: true
    },
    {
        value: 'rounded-top-circle',
        premium: true
    },
    {
        value: 'rounded-top-pill',
        premium: true
    },
]);


const THEMES_ICON_ALLIGNMENT = Object.freeze([
    {
        value: 'profile-button-one'
    },
    {
        value: 'profile-button-two'
    },
    {
        value: 'profile-button-three'
    },
    {
        value: 'profile-button-four'
    }
]);

const THEMES_WAVES = Object.freeze([
    {
        value: ''
    },
    {
        value: 'wave-one'
    },
    {
        value: 'wave-two'
    },
    {
        value: 'wave-three'
    },
    {
        value: 'wave-four',
        premium: true
    },
    {
        value: 'wave-five',
        premium: true
    },
    {
        value: 'wave-six',
        premium: true
    },
    {
        value: 'wave-seven',
        premium: true
    },
    {
        value: 'wave-eight',
        premium: true
    },
    {
        value: 'wave-nine',
        premium: true
    },
    {
        value: 'wave-ten',
        premium: true
    }
]);

const THEMES_BODY_HEADER_TYPE = Object.freeze([
    {
        value: 'header_one'
    },
    {
        value: 'header_two'
    },
    {
        value: 'header_three'
    },
    {
        value: 'header_four'
    },
    {
        value: 'header_five'
    },
    {
        value: 'header_six'
    },
    {
        value: 'header_seven'
    },
    {
        value: 'header_eight'
    },
    {
        value: 'header_nine'
    },
    {
        value: 'header_ten'
    }
]);

const THEMES_ORG_NAME_TYPE = Object.freeze([
    {
        value: 'org_name_one'
    },
    {
        value: 'org_name_two'
    },
    {
        value: 'org_name_three'
    },
    {
        value: 'org_name_four'
    },
    {
        value: 'org_name_five'
    },
    {
        value: 'org_name_six'
    },
    {
        value: 'org_name_seven'
    }
]);

const validatePremiumThemes = (theme: ThemeModel) => {
    try {
        const values: Array<string> = [
            theme?.profile,
            theme?.profileNameFontStyle,
            theme?.background,
            theme?.iconsType,
            theme?.profileWaves,
            theme?.orgName
        ];
        let result: boolean = [
            ...THEMES_HEADER_TYPE,
            ...THEMES_BACKGROUND,
            ...THEMES_FONT_FAMILY,
            ...THEMES_ICONS_ROUNDED,
            ...THEMES_WAVES,
            ...THEMES_ORG_NAME_TYPE
        ].some((data: any) => values.includes(data?.value) && data?.premium);
        if (!result) {
            result = theme?.profileButtonDynamicColor
        }
        return !result;
    } catch (err) {
        return false;
    }
}

const DEFAULT_THEME: ThemeModel = {
    profile: 'profile-one',
    profileNameFontStyle: '',
    bodyFontStyle: '',
    themeBodyStyle: 'icon',
    background: 'background_five',
    profileImageShape: 'shape_one',
    iconsType: 'rounded',
    profileWaves: '',
    profileNameFontSize: 'profile-name-size-small',
    profileNameFontWeight: '',
    profileButton: 'profile-button-one',
    profileButtonDynamicColor: false,
    header: 'header_one',
    orgName: 'org_name_one',
    themeBodyIconText: ''
}

const DEFAULT_LINK_THEME: ThemeLinkCardModel = {
    profile: 'profile-link-card-three',
    profileNameFontStyle: 'profile-font-family-six',
    bodyFontStyle: 'profile-font-family-eleven',
    background: 'background_five',
    profileImageShape: 'rounded-3',
    iconsType: 'rounded-3',
    profileNameFontSize: 'profile-name-size-small',
    profileNameFontWeight: '',
    profileButton: 'profile-button-one',
    themeBodyIconText: '',
    profileImageSize: 'profile-link-card-image-small-two'
}

export {
    THEMES_HEADER_TYPE,
    THEMES_BODY_TYPE,
    THEMES_ICONS_ROUNDED,
    THEMES_WAVES,
    DEFAULT_THEME,
    THEMES_FONT_FAMILY,
    THEMES_FONT_SIZE,
    THEMES_FONT_WEIGHT,
    THEMES_BODY_HEADER_TYPE,
    THEMES_ICON_ALLIGNMENT,
    validatePremiumThemes,
    THEMES_ORG_NAME_TYPE,
    THEMES_BODY_ICON_NAME_TYPE,
    DEFAULT_LINK_THEME
}